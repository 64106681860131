import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CssBaseline, Container, Typography, Grid2, Card, CardContent, CardActions, Button, Paper, Stack, Snackbar, Alert, Box } from '@mui/material';
import RecipeCard from "../components/specific/RecipeCard";
import ShoppingList from "../components/specific/ShoppingList";
import AppAppBar from "../components/common/AppAppBar";
import LockIcon from '@mui/icons-material/Lock'
import ConfirmUnlock from "../components/specific/ConfirmUnlock";
import Footer from "../components/common/Footer";

const Recipes = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure mealPlan from state
    const { state } = location;
    const mealPlan = state?.mealPlan;

    const [recipeOpen, setRecipeOpen] = React.useState(false);
    const [selectedRecipe, setSelectedRecipe] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    const handleRecipeOpen = (recipe) => {
        if (mealPlan.locked) {
            setSnackbarOpen(true)
            return
        } else {
            setSelectedRecipe(recipe);
            setRecipeOpen(true);
        }
    }
    
    const handleRecipeClose = () => {
        setRecipeOpen(false);
        setSelectedRecipe(null);
    }

    const [shoppingListOpen, setshoppingListOpen] = React.useState(false);
    
    const handleshoppingListOpen = () => {
        if (mealPlan.locked) {
            setSnackbarOpen(true)
            return
        } else {
            setshoppingListOpen(true);
        }
    }
    const handleshoppingListClose = () => {setshoppingListOpen(false);}

    const handleUnlock = () => {
        setConfirmationOpen(true);
    }

    // TODO: access "latest" mealplan if direct to mealplan page
    React.useEffect(() => {
        if (!mealPlan) {
            navigate('/', { replace: true });
        }
    }, [mealPlan, navigate]);

    return (
        <>
            <CssBaseline />
            <AppAppBar />
            <main>
                <div>
                    <Container maxWidth="md" sx={{ my: {xs: '40px', sm: '100px'} }}>
                    <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <span>🥗</span>
                            <span>{mealPlan.name ? mealPlan.name : "Meal Plan"}</span>
                            <span>🍕</span>
                        </Box>
                    </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                            {mealPlan.message}
                        </Typography>

                        {mealPlan.locked && (
                            <Paper style={{marginTop: '25px', justifyItems: 'center'}}>
                                <Typography variant="body1" align="center" gutterBottom>This meal plan is in preview mode! If you are happy with the selection of meals, use 1 token to unlock the recipes and shopping list.</Typography>
                                <Stack direction="row" spacing={2} style={{margin: '10px'}}>
                                    <Button variant="outlined" onClick={()=>{navigate('/')}}>Try a new prompt</Button>
                                    <Button variant="contained" onClick={handleUnlock}>Unlock 🔓</Button>
                                </Stack>
                                
                            </Paper>

                        )}

                        <Button variant="contained" onClick={() => handleshoppingListOpen()} fullWidth sx={{margin: '20px 0'}}>Shopping List 
                            {mealPlan.locked && <LockIcon
                                                        style={{
                                                            position: 'relative',
                                                            fontSize: '20px', // Adjust icon size
                                                            right: -10
                                                        }}
                                                    />
                            }
                        </Button>

                        <Grid2 container spacing={4}>
                            {mealPlan.recipes.map((recipe, index) => (
                                <Grid2 item key={index} size={[12, 6, 4]}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardContent>
                                            <Typography variant="h3" component="div" gutterBottom>
                                                {recipe.title}                                            
                                                {mealPlan.locked && (
                                                    <LockIcon
                                                        style={{
                                                            position: 'relative',
                                                            fontSize: '20px', // Adjust icon size
                                                            right: -10
                                                        }}
                                                    />
                                                )}
                                            </Typography>

                                            <Typography variant="body2" color="text.secondary">
                                                {recipe.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={() => handleRecipeOpen(recipe)}
                                                sx={{ position: 'relative' }} // Ensure the button is positioned relative
                                            >
                                                View Recipe
                                                
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Container>

                    {selectedRecipe && (
                        <RecipeCard open={recipeOpen} handleClose={handleRecipeClose} recipe={selectedRecipe} />
                    )}

                    {shoppingListOpen && (
                        <ShoppingList open={shoppingListOpen} handleClose={handleshoppingListClose} shoppingList={mealPlan.shopping_list} />
                    )}
                </div>

                <Snackbar open={snackbarOpen} autoHideDuration={1500} onClose={() => {setSnackbarOpen(false);}}>
                    <Alert onClose={() => {setSnackbarOpen(false);}} severity="warning" sx={{ width: '100%' }}>
                        Unlock this meal plan for 1 credit
                    </Alert>
                </Snackbar>

                <ConfirmUnlock open={confirmationOpen} handleClose={() => {setConfirmationOpen(false);}} mealPlan={mealPlan} />
            </main>

            <Footer />
        </>
    )
}

export default Recipes;