import { Divider, Grid, Typography, Tabs, Tab, Button, Box, Dialog, DialogTitle, DialogActions, List, ListItem, ListItemText, DialogContent } from "@mui/material";
import React from "react";

const RecipeCard = ({ open, handleClose, recipe }) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    
    if (!recipe) return null;

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    }

    return(
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>{recipe.title}</DialogTitle>
            <DialogContent>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Ingredients" />
                    <Tab label="Steps" />
                </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                    <List>
                    {recipe.ingredients.map((ingredient, index) => (
                        <ListItem key={index}>
                        <ListItemText primary={`• ${ingredient.name}, ${ingredient.quantity.amount} ${ingredient.quantity.unit}`} />
                        </ListItem>
                    ))}
                    </List>
                )}
                {tabIndex === 1 && (
                    <List>
                        {recipe.steps.map((step, index) => (
                            <>
                            <ListItem key={index}>
                                <Grid container spacing={0} margin={1.5} >
                                    {/* Column for step.name */}
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body1" fontWeight="bold">
                                            {step.name}:
                                        </Typography>
                                    </Grid>
                                    {/* Column for step.content */}
                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="body1" color="#D3D3D3">
                                            {step.content}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Divider component="li" sx={{background: '#D3D3D3'}}/>
                            </>
                        ))}
                    </List>
                )}
            </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default RecipeCard