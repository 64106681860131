import React, { useContext } from "react";
import { Container, CssBaseline, Typography, TextField, Box, Button, Snackbar, Alert } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import LoadingBackdrop from "../components/common/loading";
import { useNavigate } from 'react-router-dom';
import AppAppBar from "../components/common/AppAppBar";
import { UserContext } from "../contexts/UserContext";
import axiosInstance from "../contexts/axiosInstance";
import Footer from "../components/common/Footer";
import Highlights from "../components/specific/Highlights";

const portionSizes = [
    {value: 'Small', label: 'Small (500 kcals)'},
    {value: 'Medium', label: 'Medium (750 kcals)'},
    {value: 'Large', label: 'Large (1000 kcals)'},
    {value: 'XL', label: 'XL (1250 kcals)'},
]

const Home = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate()

    const promptRef = React.useRef(null);
    const portionsRef = React.useRef(null);
    const sizeRef = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleGenerate = async (event) => {
        event.preventDefault()

        if (!user) {
            setSnackbarOpen(true);
            return;
        }

        setLoading(true);

        const inputText = promptRef.current.value;
        const portions = portionsRef.current.value;
        const portionSize = sizeRef.current.value;

        axiosInstance
            .post('/api/generate/', {
            inputText,
            portions,
            portionSize,
            },
            {
                headers: {  
                    Authorization: `Bearer ${user.token}`
                }
            })
            .then((response) => {
                const mealPlan = response.data;
                navigate('/recipes', { state: { mealPlan } });
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });  
    };

    return (
        <>
            <CssBaseline />
            <AppAppBar />
            <main>
                <div> 
                    <Container maxWidth="md" sx={{ my: {xs: '40px', sm: '110px'} }}>
                        <Typography variant="h2" align="center" color="textPrimary" gutterBottom>Magic Meals ✨</Typography>
                        <Typography variant="body1" align="center" color="textSecondary" gutterBottom sx={{display: {xs:'none', small: 'block', md: 'block'}}}>
                            An AI tool to help you plan your meals! No more digging through cookbooks, cancelling HelloFresh after weeks of throwing away food only to be left with loads of tiny sachets of soy sauce, or running to Tesco to grab a last minute ready meal. Simply type in your preferences and add the food to your weekly online shop, as and when you need!
                        </Typography>
                        <TextField id="filled-basic" label="What do you want to eat this week..." inputRef={promptRef} sx={{m: '25px 0'}} variant="filled" multiline rows={4} margin="normal" fullWidth />
                        <Box component={'form'} sx={{ '& .MuiTextField-root': { margin: {xs: "20px 0 ", sm: '0.5'}, width: {xs: '100%', sm: '25ch' } }}}>
                            <Box
                                sx={{
                                display: 'flex', // Enable Flexbox
                                alignItems: 'center', // Align items vertically center
                                justifyContent: 'space-between', // Distribute space between items
                                flexWrap: 'wrap', // Allow items to wrap on smaller screens
                                margin: {xs: '25px 0', sm: '25px 0'}
                                }}
                            >
                                <TextField
                                    id="outlined-select"
                                    select
                                    label="Portion Sizes"
                                    inputRef={sizeRef}
                                    defaultValue="Medium"
                                    helperText="How hungry are you?"
                                >
                                {portionSizes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                                </TextField>
                                <TextField 
                                    id="outlined-number" 
                                    label="Portions per meal" 
                                    inputRef={portionsRef}
                                    variant="outlined" 
                                    type="number"
                                    defaultValue={1}
                                    helperText="Will you be sharing / reheating?"
                                    slotProps ={{
                                        min: 1, // Minimum value
                                        max: 10, // Maximum value
                                        step: 1, // Step interval
                                      }}
                            
                                />
                            </Box>
                            <Button variant="contained" fullWidth onClick={handleGenerate}>Generate ✨</Button>
                        </Box>
                    </Container>

                    <LoadingBackdrop open={loading} />

                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => {setSnackbarOpen(false);}}>
                        <Alert onClose={() => {setSnackbarOpen(false);}} severity="warning" sx={{ width: '100%' }}>
                            You need to be signed in to use this feature.
                        </Alert>
                    </Snackbar>

                    <Highlights />

                    
                </div>
            </main>

            <Footer />

        </>
    )
}

export default Home;