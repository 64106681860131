import React from 'react';
import { Drawer, IconButton, Typography, ListItemText, List, ListItem, Divider, ListItemButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LockIcon from '@mui/icons-material/Lock';
import axiosInstance from '../../contexts/axiosInstance';
import { useNavigate } from 'react-router-dom';

export default function HistorySidebar({open, handleClose, user}) {
    const [mealPlanHistory, setMealPlanHistory] = React.useState([]);
    const navigate = useNavigate();

    // Fetch meal plan history from Django API
    React.useEffect(() => {
        if (open) {
            // Make sure to replace this URL with your actual endpoint
            axiosInstance.get('/api/mealplans/',
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                }
            )
                .then(response => {
                    setMealPlanHistory(response.data);
                })
                .catch(error => {
                    console.error('Error fetching meal plan history:', error);
                });
        }
    }, [open, user?.token]);

    const handleSelectMealplan = (id) => {
        axiosInstance.get(`/api/meal-plan/${id}`, {
            headers: { Authorization: `Bearer ${user.token}` }
          })
            .then((response) => {
                const mealPlan = response.data;
                navigate('/recipes', { state: { mealPlan } });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return(
        <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
            width: 250, // Adjust this value for a wider sidebar
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 250, // Ensure this matches the drawer width
                boxSizing: 'border-box',
                padding: '16px',
                overflowY: 'auto',
            }
        }}>
            <IconButton color='inherit' onClick={handleClose} sx={{position: 'absolute', top: '16px', left: '16px'}}>
                <ChevronLeftIcon />
            </IconButton>

            <Typography variant="h6" sx={{ marginTop: '64px', paddingBottom: '16px' }}>
                History
            </Typography>

            <Divider color='inherit' sx={{ marginBottom: '16px' }} />

        {/* Meal Plan History List */}
        <List>
            {mealPlanHistory.length > 0 ? (
                mealPlanHistory.map((mealPlan, index) => (
                    <ListItemButton key={index} onClick={() => handleSelectMealplan(mealPlan.id)}>
                        <ListItemText 
                            primary={
                                <>
                                    <Typography variant='body1'>{mealPlan.name ? mealPlan.name : "Meal Plan"}</Typography>
                                    <Typography variant='body2'>{`${new Date(mealPlan.created_at).toLocaleString('en-GB')}`}</Typography>
                                    {mealPlan.locked && (
                                        <LockIcon 
                                            style={{
                                                position: 'absolute', 
                                                right: 0, 
                                                top: '50%', 
                                                transform: 'translateY(-50%)', 
                                                marginLeft: '8px'
                                            }} 
                                        />
                                    )}
                                </>
                            }
                        />
                    </ListItemButton>
                ))
            ) : (
                <ListItem>
                    <ListItemText primary="No history found." />
                </ListItem>
            )}
        </List>
        </Drawer>
    )
}