
import React from "react"
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { Container, Box, Typography, Stack } from "@mui/material"
import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <Container
        maxWidth={false}

        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          px: {xs: 8, sm:10},
          textAlign: { sm: 'center', md: 'left' },
          backgroundColor: "#000e37",

        }}>
            <Box
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                width: '100%',
                justifyContent: 'space-between',
                }}
            >
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    minWidth: { xs: '100%', sm: '60%' },
                }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                        {/*TODO: site icon */}
                        <img src="/icons8-hamburger-32.png"  alt="Magic Meals Icon" width={60} height={60} />
                        <Typography variant="h3" gutterBottom>
                        Magic Meals
                        </Typography>
                        <Typography variant="body2">
                        Full stack developed by Edward Owens.
                        </Typography>
                    </Box>
                </Box>

                <Box
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    flexDirection: 'column',
                    gap: 1,
                }}
                >
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    Browse
                </Typography>
                <Link variant="body2" to="/">
                    Home
                </Link>
                <Link variant="body2" to="/sign-up">
                    Sign Up
                </Link>
                <Link variant="body2" to="/sign-in">
                    Sign In
                </Link>
                <Link variant="body2" to="/credits">
                    Credits
                </Link>
                </Box>

                <Box
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    flexDirection: 'column',
                    gap: 1,
                }}
                >
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    Contact
                </Typography>
                <Stack direction={'row'} gap={1}>
                    <EmailIcon />
                    <Typography color="text.secondary">
                        edwowe99@gmail.com
                    </Typography>
                </Stack>

                <Stack direction={'row'} gap={1}>
                    <LinkedInIcon />
                    <Link color="text.secondary" target="_blank" to="https://www.linkedin.com/in/edward-p-owens">
                        edward-p-owens
                    </Link>
                </Stack>

                <Stack direction={'row'} gap={1}>
                    <FacebookIcon />
                    <Link color="text.secondary" target="_blank" to="https://github.com/edwowe99">
                        edwowe99
                    </Link>
                </Stack>
                </Box>
            </Box>
        </Container>

    )
}