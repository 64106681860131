import { Typography, Box, Dialog, DialogTitle, DialogActions, Button, ListItemText, DialogContent, List, ListItem } from "@mui/material";
import React from "react";

const ShoppingList = ({ open, handleClose, shoppingList }) => {
    if (!shoppingList) return null;

    const renderCategory = (title, description, items) => (
        <>
            <Typography variant="h3" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontStyle: 'italic' }}>
                {description}
            </Typography>
            <List>
                {items.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={`${item.name}: ${item.quantity} ${item.unit}`} />
                    </ListItem>
                ))}
            </List>
        </>
    );

    return(
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Shopping List</DialogTitle>
            <DialogContent>
            <Box sx={{ padding: 2 }}>
                    {renderCategory(
                        "Check if you have",
                        "These are common pantry items, needed in small quantities.",
                        shoppingList.check_if_you_have
                    )}
                    {renderCategory(
                        "Essential",
                        "These are the core ingredients for your recipes.",
                        shoppingList.essential
                    )}
                    {renderCategory(
                        "Optional",
                        "These items can be skipped if you already have alternatives or are okay without them.",
                        shoppingList.optional
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>

    )
}

export default ShoppingList