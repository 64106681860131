import React, { createContext, useState, useEffect  } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, getIdToken } from "firebase/auth";
import { auth } from "../firebase/config";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch and set user token
    const fetchAndSetToken = async (firebaseUser) => {
        try {
            const token = await getIdToken(firebaseUser);
            setUser({ ...firebaseUser, token });
        } catch(error) {
            console.error("Error fetching token:", error);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                await fetchAndSetToken(firebaseUser);
            } else {
                setUser(null);
            }
            setLoading(false);
        });
    
        return () => unsubscribe();
    }, []);

    const login = async (email, password, useGoogleSignIn = false) => {
        try {
            if (useGoogleSignIn) {
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);
                await fetchAndSetToken(userCredential.user);
                return { success: true };
            } else {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                await fetchAndSetToken(userCredential.user);
                return { success: true };
            }
        } catch (error) {
            console.error("Login error:", error);
            return { success: false, error };
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, login, logout, loading }}>
          {!loading && children}
        </UserContext.Provider>
      );
};