import React from "react";
import AppAppBar from "../components/common/AppAppBar";
import { Box, Button, Chip, Container, CssBaseline, Divider, Grid, Paper, Typography } from "@mui/material";
import axiosInstance from "../contexts/axiosInstance";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import Footer from "../components/common/Footer";

const creditOptions = [
    { key: 'try', label: 'Try it out', credits: 1, price: '£1', ppt:"£1" },
    { key: 'recommended', label: 'Recommended', credits: 5, price: '£2', ppt:"40p" },
    { key: 'frequent', label: 'Frequent User', credits: 25, price: '£5', ppt:"20p" }
  ];


const Credits = () => {
    const { user } = React.useContext(UserContext);
    const [userCredits, setUserCredits] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState('recommended');
    const navigate = useNavigate();

    const handleSelectOption = (optionKey) => {
        setSelectedOption(optionKey);
        };

    const handlePay = async () => {
        try {
            const token = user.token; // You will need to implement this to get the Firebase auth token
            const response = await axiosInstance.post(
                '/api/stripe/create-checkout-session/', 
                {
                    product_name: selectedOption  // Send the selected option as 'product_name'
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Pass the Firebase token in the Authorization header
                        'Content-Type': 'application/json'  // Ensure the request body is JSON
                    }
                }
            );
    
            if (response.status === 200) {
                // If the response is successful, redirect the user to Stripe checkout
                window.location.href = response.data.checkout_url;
            } else {
                console.error("Error:", response.data.error);
                alert("There was an error with the payment process.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong, please try again.");
        }
    };
        
        

    React.useEffect(() => {
        if (!user?.token) {
          // If no token (i.e. user is not logged in), redirect to the sign-in page
          navigate('/sign-in');
          return;
        }   
        // If the user is logged in, fetch their details
        axiosInstance.get('/api/user-details/', {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          })
          .then(response => {
            setUserCredits(response.data.credits);
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
    }, [user?.token, navigate]);

    return (
        <>
        <CssBaseline />
        <AppAppBar />
        
        <main>
        <Container maxWidth="md" sx={{my: {xs: "30px", sm: "75px"}}}>
            <Typography variant="h1" align="center" gutterBottom>Buy Credits</Typography>
            <Typography variant="body1" align="center" gutterBottom>Simply use one credit to unlock a week's meal plan (including recipes and shopping list!). You can try out as many prompts as you like before confirming and using a credit.</Typography>
        

            <Paper elevation={3} style={{ padding: '1rem', margin: '2rem' }}>
                <Typography variant="h6" align="center">
                Current credit balance: {userCredits}👛
                </Typography>
            </Paper>

            <Grid container spacing={3} justifyContent="center">
                {creditOptions.map((option) => {
                const isSelected = selectedOption === option.key;
                return (
                    <Grid item xs={12} sm={4} key={option.key}>
                    <Paper
                        elevation={isSelected ? 8 : 2}
                        onClick={() => handleSelectOption(option.key)}
                        style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        padding: '1rem',
                        border: isSelected ? '2.5px solid rgba(247, 231, 0, 0.88)' : '1px solid #ccc'
                        }}
                    >
                        <Box
                        sx={[
                            {
                            mb: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 2,
                            },
                        ]}
                        >
                        <Typography component="h3" variant="h6">
                            {option.credits === 1 ? '1 credit' : `${option.credits} credits`}
                        </Typography>
                        {/*TODO: alter the eolors for each chip! */}
                        <Chip label={option.label} color="secondary"/>
                        </Box>
                        <Box
                            sx={[
                                {
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center'
                                },
                            ]}
                            >
                        <Typography component="h3" variant="h3">
                            {option.ppt}
                        </Typography>
                        <Typography component="h3" variant="h6">
                            &nbsp; per credit
                        </Typography>
                        </Box>

                        <Divider sx={{ my: 4 }} />

                        <Paper elevation={4} sx={{ p: 3, background: 'linear-gradient(to right top, rgba(220, 224, 18, 0.87),rgb(219, 27, 203))'  }}>
                            <Typography variant="h5" align="center">
                                {option.price}
                            </Typography>
                        </Paper>

                    </Paper>
                    </Grid>
                );
                })}
            </Grid>

            <Box mt={4} textAlign="center">
                <Button
                variant="contained"
                color="secondary"
                onClick={handlePay}
                disabled={!selectedOption}
                fullWidth
                >
                Pay with Stripe
                </Button>
            </Box>
        </Container>


        </main>

        <Footer />
        </>
    )
};

export default Credits;