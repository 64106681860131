import { AppBar, Container, Toolbar, Box, Button, Typography, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import HistorySidebar from "./historySidebar";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import axiosInstance from "../../contexts/axiosInstance";

export default function AppAppBar() {
    const { user, logout } = useContext(UserContext);
    const navigate = useNavigate();

    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [userCredits, setUserCredits] = React.useState(null);

    const handleSignOut = () => {
        logout();
        navigate('/');
    }

    // Fetch meal plan history from Django API
    React.useEffect(() => {
      if (user) {
          // Make sure to replace this URL with your actual endpoint
          axiosInstance.get('/api/user-details/',
              {
                  headers: {
                      Authorization: `Bearer ${user.token}`
                  }
              }
          )
              .then(response => {
                  setUserCredits(response.data.credits);
              })
              .catch(error => {
                  console.error('Error fetching user details:', error);
              });
      }
    }, [user?.token]);

    return (
        <AppBar
            position="sticky"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 'calc(var(--template-frame-height, 0px) + 28px)',
              }}
        >
            <Container maxWidth="lg">
                <Toolbar sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexShrink: 0,
                    borderRadius: '8px',
                    backdropFilter: 'blur(24px)',
                    border: '1px solid',
                    padding: '8px 12px',
                }}>
                  
                  <IconButton
                   color="inherit" 
                   aria-label="open sidebar" 
                   sx={{display: sidebarOpen ? 'none' : 'flex'}}
                   onClick={()=>{setSidebarOpen(true);}}>
                    <MenuIcon />
                  </IconButton>

                  <IconButton
                  color="inherit"
                  aria-label="homepage"
                  sx={{display: 'flex'}}
                  onClick={() => {navigate('/')}}>
                    <HomeIcon />
                  </IconButton>
                
                  {/* Spacer between sidebar icon and login/logout button */}
                  <Box sx={{flexGrow: 1}} />

                  <Box
                      sx={{
                      display: 'flex' ,
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'right'
                      }}
                  >
                  {user ? (
                      <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <Typography variant="body1" color="text.primary" sx={{ display: {xs: 'none', sm: 'block'}}}>
                          Hello, {user.email}
                        </Typography>
                        <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          {userCredits} 👛
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={()=>{navigate('/credits')}}
                        >
                          Get Credits
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={handleSignOut}
                        >
                          Sign Out
                        </Button>
                      </Box>
                    </Box>
                      ) : (
                      <>
                          <Button
                          color="primary"
                          variant="text"
                          size="small"
                          onClick={() => navigate("/sign-in")}
                          >
                          Sign in
                          </Button>
                          <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => navigate("/sign-up")}
                          >
                          Sign up
                          </Button>
                      </>
                      )}
                  </Box>
                </Toolbar>

            </Container>
            
          <HistorySidebar open={sidebarOpen} handleClose={() => {setSidebarOpen(false);}} user={user} />
        </AppBar>

    )
}