import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
            primary: {
              main: '#c51162', // Dark blue
              contrastText: '#ffffff',
            },
            secondary: {
              main: '#000000', // Black
              contrastText: '#c51162',
            },
            background: {
              default: '#000000', // Set to black
              paper: 'hsl(210, 90%, 20%)',
            },
            text: {
                primary: '#ffffff',
                secondary: '#ffffff',
                contrastText: '#c51162'
            }
    },

    components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              background: 'radial-gradient(ellipse 90% 50% at 50% 50%, hsl(210, 98%, 35%),  hsl(210, 100%, 16%))', // Dark blue gradient
              minHeight: '100vh',
              margin: 0,
              padding: 0,
            },
          },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 0, // Adjust as needed
                    paddingBottom: 0, // Adjust as needed
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                background: '#c51162'
            }
        }
      },

    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
          fontSize: '4rem',
          fontWeight: 700,
          lineHeight: 1.2,
        },
        h2: {
          fontSize: '2.5rem',
          '@media (min-width:600px)': {
              fontSize: '3.5rem',
          },
          fontWeight: 600,
          lineHeight: 1.2,
        },
        h3: {
          fontSize: '1.2rem',
          '@media (min-width:600px)': {
            fontSize: '1.5rem',
          },
          fontWeight: 500
        },
        body1: {
          fontSize: '0.85rem',
          '@media (min-width:600px)': {
              fontSize: '1rem',
          },
          lineHeight: 1.5,
        },
        body2: {
          fontSize: '0.75rem',
          lineHeight: 1.5,
        },
    }
})

export default theme;