import React from "react";
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingBackdrop({open}) {  
    return (
      <div>
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }