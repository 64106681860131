import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import theme from "./theme/theme";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { UserProvider } from "./contexts/UserContext";

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserProvider>
                <App />
            </UserProvider>
        </ThemeProvider>
    </React.StrictMode>
);