import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../contexts/axiosInstance";
import { UserContext } from "../../contexts/UserContext";

const ConfirmUnlock = ({ open, handleClose, mealPlan }) => {

    const { user } = React.useContext(UserContext);

    const [userCredits, setUserCredits] = React.useState(null);
    const navigate = useNavigate();

    const handleConfirm = async (event) => {
        event.preventDefault()

        axiosInstance
            .post(`/api/unlock-meal-plan/${mealPlan.id}/`, {},
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then((response) => {
                const mealPlan = response.data;
                navigate('/recipes', { state: { mealPlan } });
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    React.useEffect(() => {
        if (!user?.token) {
          // If no token (i.e. user is not logged in), redirect to the sign-in page
          navigate('/sign-in');
          return;
        }   
        // If the user is logged in, fetch their details
        axiosInstance.get('/api/user-details/', {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          })
          .then(response => {
            setUserCredits(response.data.credits);
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
    }, [user?.token, navigate]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Unlock this meal plan for 1 credit?</DialogTitle>
            {userCredits > 0 ? (
            <DialogContent>
                    <Typography variant="body1" gutterBottom>You currently have {userCredits} credits.</Typography>
                    <Typography variant="body2" gutterBottom>Use one token to unlock the recipes and shopping list. If you have any issues with the mealplan, simply contact the dev team, explain the problem and we will refund your token or make it right for you!</Typography>
                    <Stack direction="row" marginTop="20px" justifyContent="space-between">
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleConfirm}>Confirm 🔓</Button>
                    </Stack>

            </DialogContent>
            ) : (
            <DialogContent>
                <Typography>You have no credits!</Typography>
                <Stack direction="row">
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={() => {navigate('/credits')}}>Buy Credits</Button>
                </Stack>
            </DialogContent>
        )}
        </Dialog>
    )
}

export default ConfirmUnlock