import { Card, CardContent, Container, Grid2, Typography } from "@mui/material";
import { styled } from '@mui/system';
import RecyclingIcon from '@mui/icons-material/Recycling';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import React from "react";

const HighlightCard = styled(Card) ({
    height: '100%', display: 'flex', flexDirection: 'column', background: 'linear-gradient(to right top, hsla(210, 100.00%, 16.10%, 0.70), hsla(210, 100.00%, 25.10%, 0.85),rgba(219, 27, 203, 0.96))'
})

export default function Highlights() {
    return (
        <Container maxWidth={false} sx={{backgroundColor: "hsl(210, 90%, 20%)", padding: '20px'}}>
            <Container sx={{my:{xs:'20px', sm:'40px'}}}>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom>Why Magic Meals?</Typography>
                <Typography variant="body1" align="center" color="textPrimary">Bespoke meal plans in a matter of seconds, tailored to your exact requirements!</Typography>

                <Grid2 container spacing={4} sx={{my:{xs: '20px', sm:'50px'}}}>
                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <MenuBookIcon />
                                <Typography variant="h3" component="div" gutterBottom>Nutrition</Typography>
                                <Typography variant="body2">Our model is specified to carefully fit your nutrional needs, simply select your desired portion sizes and we'll do the rest! No shrinkflation, no child sized portions, no skimping on quality ingredients</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>

                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <RecyclingIcon />
                                <Typography variant="h3" component="div" gutterBottom>Sustainability</Typography>
                                <Typography variant="body2">Try asking for a sustainable meal plan! Once created, you have the freedom to shop at your local refill store, regenerative farm or plastic free butchers! Whilst their is a small environmental cost to running the website, we have aimed to minimize this and believe it is minimal in comparison to 5ml soy sauce packets :D</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>

                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <CurrencyPoundIcon />
                                <Typography variant="h3" component="div" gutterBottom>Cost effective</Typography>
                                <Typography variant="body2">Try asking for a budget meal plan. For the low cost of a credit, you can generate novel meal plans and make eating on a budget not seem like a form of self torture!  Best of all, our pricing is completely transparent: no "trap" subscription that you have to remember to cancel, no hidden costs.</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>

                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <EmojiEmotionsIcon />
                                <Typography variant="h3" component="div" gutterBottom>Ease of use</Typography>
                                <Typography variant="body2">Simply type your requirements in natural language and get a comprehensive meal plan, with step by step recipes and a collated shopping list that you can quickly copy into your online shop!</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>

                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <SupportAgentIcon />
                                <Typography variant="h3" component="div" gutterBottom>Reliable support</Typography>
                                <Typography variant="body2">If you have any issues, we want to know so we can improve our service! We guarantee to make it right AND give you your money back if you let us know of any problems!</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>

                    <Grid2 size={[12,6, 4]}>
                        <HighlightCard>
                            <CardContent>
                                <QuestionMarkIcon />
                                <Typography variant="h3" component="div" gutterBottom>Free to try!</Typography>
                                <Typography variant="body2">Generate meal plans for free! New accounts are credited with 2 free tokens to see what all the fuss is about! Why wait? Create an account and get started.</Typography>
                            </CardContent>
                        </HighlightCard>
                    </Grid2>
                </Grid2>

        
            </Container>
        </Container>
    )
};